<ng-container *transloco="let translate">
  <cv-panel id="experience" [header]="translate('cv.missions.panelTitle')">
    <div class="timeline">
      @for (mission of missions; track mission['client']; let last = $last) {
        <div class="timeline__event">
          <div class="timeline__event-opposite"></div>
          <div class="timeline__event-separator">
            <div class="timeline__event-separator__icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <g id="bookmark">
                  <path
                    d="M17.75,20.75a.83.83,0,0,1-.43-.13L12,16.91,6.68,20.62a.75.75,0,0,1-.78,0A.74.74,0,0,1,5.5,20V6A2.75,2.75,0,0,1,8.25,3.25h7.5A2.75,2.75,0,0,1,18.5,6V20a.74.74,0,0,1-.4.66A.73.73,0,0,1,17.75,20.75ZM12,15.25a.75.75,0,0,1,.43.13L17,18.56V6a1.25,1.25,0,0,0-1.25-1.25H8.25A1.25,1.25,0,0,0,7,6V18.56l4.57-3.18A.75.75,0,0,1,12,15.25Z"
                  />
                </g>
              </svg>
            </div>
            @if (!last) {
              <div class="timeline__event-connector"></div>
            }
          </div>
          <div class="timeline__event-content">
            <div class="mission">
              <div class="mission__header">
                <span class="mission__header__client">
                  {{ mission['client'] }}
                </span>
                <span class="mission__header__timelapse">
                  {{ mission['timelapse'] }}
                </span>
              </div>
              <div class="mission__body">
                <markdown [innerHTML]="mission.description"></markdown>
                <div class="mission__body__button">
                  <cv-slide-button
                    (click)="emitOpenMissionDialog(mission)"
                    onKeyDown="emitOpenMissionDialog(mission)"
                  ></cv-slide-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </cv-panel>
</ng-container>
