<div class="chip">
  <svg class="chip__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
    <g id="envelope">
      <path
        d="M19,4.25H5A1.76,1.76,0,0,0,3.25,6V18A1.76,1.76,0,0,0,5,19.75H19A1.76,1.76,0,0,0,20.75,18V6A1.76,1.76,0,0,0,19,4.25ZM5,5.75H19a.25.25,0,0,1,.25.25V7.54L12,11.16,4.75,7.54V6A.25.25,0,0,1,5,5.75Zm14,12.5H5A.25.25,0,0,1,4.75,18V9.21l6.91,3.46a.76.76,0,0,0,.68,0l6.91-3.46V18A.25.25,0,0,1,19,18.25Z"
      />
    </g>
  </svg>
  <div class="chip__label">{{ label }}</div>
</div>
