<div class="scroll-wrapper">
  <div id="scroll-tracker"></div>
</div>
<cv-summary></cv-summary>
<cv-skills [skills]="skills" [clones]="clones"></cv-skills>
<cv-missions [missions]="missions" (openDialog)="openDialog($event)"></cv-missions>
<cv-hobbies></cv-hobbies>
<cv-details
  [selectedMission]="selectedMission"
  [isDialogDisplayed]="displayDialog"
  (detailsChange)="onDetailsClose()"
></cv-details>
