@if (isLoading$ | async) {
  <p-progressSpinner strokeWidth="6" styleClass="spinner"></p-progressSpinner>
} @else {
  <div id="container">
    <aside>
      <cv-general></cv-general>
    </aside>
    <main>
      <cv-resume></cv-resume>
      <cv-scroll-top></cv-scroll-top>
    </main>
  </div>
}
