<ng-container *transloco="let translate">
  <div class="container">
    <div class="general">
      <cv-avatar class="p-mt-5" avatar="assets/pictures/me.jpg"></cv-avatar>
      <span class="general__name">Gilles Gardet</span>
      <span class="general__presentation">{{ translate('cv.contact.presentation') }}</span>
      <cv-email
        label="gilles.gardet@gmail.com"
        icon="pi pi-envelope"
        (click)="openEmailClient()"
        onKeyDown="openEmailClient()"
      ></cv-email>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-bars"
        class="p-button-rounded p-button-text general__button-left"
        [pTooltip]="translate('cv.contact.menu.tooltip')"
        (click)="menu.toggle($event)"
      ></button>
      <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body" [autoZIndex]="true"></p-menu>
    </div>
  </div>
</ng-container>
